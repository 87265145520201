
import * as ipxRuntime$69UGk6MshU from 'C:/Dev/Git/Groenhart.Webshop.App/groenhart-app/node_modules/@nuxt/image/dist/runtime/providers/ipx'

export const imageOptions = {
  "screens": {
    "xs": 320,
    "sm": 576,
    "md": 768,
    "lg": 992,
    "xl": 1200,
    "xxl": 1400,
    "2xl": 1536
  },
  "presets": {},
  "provider": "ipx",
  "domains": [
    "groenhart.nl",
    "static.groenhart.nl",
    "accept.groenhart.nl",
    "groenhart-v8.local"
  ],
  "alias": {},
  "densities": [
    1,
    2
  ],
  "format": [
    "webp"
  ]
}

imageOptions.providers = {
  ['ipx']: { provider: ipxRuntime$69UGk6MshU, defaults: undefined }
}
        